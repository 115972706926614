export const MoonIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.3234 21.128C16.4463 21.128 10.8668 15.5536 10.8668 8.67126C10.8668 7.02077 11.1886 5.44285 11.7804 4C7.21809 5.8426 4 10.3166 4 15.5433C4 22.4204 9.57956 28 16.4567 28C21.6832 28 26.1573 24.7768 28 20.2145C26.557 20.8062 24.9791 21.128 23.3234 21.128Z"
      fill="currentColor"
    />
  </svg>
)
