export const ArrowIcon = () => (
  <svg
    width="30"
    height="17"
    viewBox="0 0 30 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.48484 0.819911C0.749844 1.55491 0.749844 2.73991 1.48484 3.47491L13.9498 15.9399C14.5348 16.5249 15.4798 16.5249 16.0648 15.9399L28.5298 3.47491C29.2648 2.73991 29.2648 1.55491 28.5298 0.819911C27.7948 0.0849115 26.6098 0.0849115 25.8748 0.819911L14.9998 11.6799L4.12484 0.804914C3.40484 0.084914 2.20484 0.0849115 1.48484 0.819911Z"
      fill="currentColor"
    />
  </svg>
)
